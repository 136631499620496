import { gql } from '@apollo/client';

export const NovaTransaction_NovaBuyTransactionFragment = gql`
  fragment NovaTransaction_NovaBuyTransactionFragment on NovaBuyTransaction {
    id
    audAmount
    audNetAmount
    audTradeAmount
    usdTradeAmount
    units
    unitPrice
    usdUnitPrice
    audFxFee
    audResidualAmount
    status
    statusMessage
    cancellable
    createdAt
    completedAt
    asset {
      id
      name
      symbol
      assetGroup
    }
    bankAccount {
      id
      bsb
      accountNumber
    }
  }
`;

export const NovaTransaction_NovaSellTransactionFragment = gql`
  fragment NovaTransaction_NovaSellTransactionFragment on NovaSellTransaction {
    id
    audNetAmount
    audTradeAmount
    usdTradeAmount
    units
    unitPrice
    usdUnitPrice
    audFxFee
    status
    statusMessage
    cancellable
    createdAt
    completedAt
    asset {
      id
      name
      symbol
      assetGroup
    }
    bankAccount {
      id
      bsb
      accountNumber
    }
  }
`;

export const NovaTransaction_NovaRegulatoryFeeTransactionFragment = gql`
  fragment NovaTransaction_NovaRegulatoryFeeTransactionFragment on NovaRegulatoryFeeTransaction {
    id
    audAmount
    status
    statusMessage
    createdAt
    completedAt
    type
  }
`;

export const NovaTransaction_NovaDividendTransactionFragment = gql`
  fragment NovaTransaction_NovaDividendTransactionFragment on NovaDividendTransaction {
    id
    audNetAmount
    audAmount
    usdAmount
    units
    audPerShare
    usdPerShare
    audWithholdingTax
    status
    statusMessage
    createdAt
    completedAt
    asset {
      id
      name
      symbol
      assetGroup
    }
    bankAccount {
      id
      bsb
      accountNumber
    }
  }
`;

export const NovaTransaction_NovaAccountFeeTransactionFragment = gql`
  fragment NovaTransaction_NovaAccountFeeTransactionFragment on NovaAccountFeeTransaction {
    id
    status
    createdAt
    completedAt
    statusMessage
    audAmount
    audDiscountAmount
    audNetAmount
    coverageStartDate
    coverageEndDate
    bankAccount {
      id
      bsb
      accountNumber
    }
  }
`;

export const NovaTransaction_NovaTransactionsConnectionEdgeFragment = gql`
  fragment NovaTransaction_NovaTransactionsConnectionEdgeFragment on NovaTransactionsConnectionEdge {
    node {
      id
      ...NovaTransaction_NovaBuyTransactionFragment
      ...NovaTransaction_NovaSellTransactionFragment
      ...NovaTransaction_NovaRegulatoryFeeTransactionFragment
      ...NovaTransaction_NovaDividendTransactionFragment
      ...NovaTransaction_NovaAccountFeeTransactionFragment
    }
  }
  ${NovaTransaction_NovaBuyTransactionFragment}
  ${NovaTransaction_NovaSellTransactionFragment}
  ${NovaTransaction_NovaRegulatoryFeeTransactionFragment}
  ${NovaTransaction_NovaDividendTransactionFragment}
  ${NovaTransaction_NovaAccountFeeTransactionFragment}
`;

export const NovaTransactionLimits_NovaAssetFragment = gql`
  fragment NovaTransactionLimits_NovaAssetFragment on NovaAsset {
    id
    transactionLimits {
      createInvestmentPlanAvailable
      investmentPlanNotAvailableMessage
      investmentPlanMinAudAmount
      investmentPlanMaxAudAmount
      directDebitBuyAvailable
      directDebitBuyNotAvailableMessage
      directDebitBuyMinAudAmount
      directDebitBuyMaxAudAmount
      payIdBuyAvailable
      payIdBuyNotAvailableMessage
      payIdBuyMinAudAmount
      payIdBuyMaxAudAmount
      sellAvailable
      sellNotAvailableMessage
      sellMinUnits
      sellMaxUnits
    }
  }
`;

export const SaverTransactionLimits_SaverProductInstanceFragment = gql`
  fragment SaverTransactionLimits_SaverProductInstanceFragment on SaverProductInstance {
    id
    transactionLimits {
      createInvestmentPlanAvailable
      investmentPlanNotAvailableMessage
      directDebitBuyAvailable
      directDebitBuyNotAvailableMessage
      sellAvailable
      sellNotAvailableMessage
    }
  }
`;
